import { BLOCKS } from '@contentful/rich-text-types';
import { Box, Typography } from '@mui/material';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import React from 'react';

const AnswerExpanded = (props) => {
    const { data } = props;

    //console.log(data);
    const renderingOptions = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <>{children}</>,
        },
    };
    let date = new Date(data?.createdAt);
    const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    return (
        <Box component="div" style={{ padding: '16px' }}>
            <section>
                {dateOptions && (
                    <Typography style={{ fontSize: '14px', lineHeight: '23px', marginBottom: '11px' }}>
                        Published: {date.toLocaleDateString('en-US', dateOptions)}
                    </Typography>
                )}
                <Typography
                    style={{
                        margin: '0',
                        fontWeight: '600',
                        fontSize: '1.25rem',
                        lineHeight: '1.5rem',
                        marginBottom: '28px',
                    }}
                >
                    {data?.question}
                </Typography>
            </section>

            <section>
                <Box component="div" className="how-do-i" style={{ marginBottom: '32px' }}>
                    {renderRichText(data?.answer, renderingOptions)}
                </Box>
            </section>

            <section>
                {data?.detailsHeader1 && (
                    <>
                        <Box component="div">
                            <Typography
                                style={{
                                    fontSize: '16px',
                                    lineHeight: '23px',
                                    marginBottom: '28px',
                                    fontWeight: '800',
                                }}
                            >
                                {data?.detailsHeader1}
                            </Typography>
                        </Box>
                        {data?.detailsDescription1 && (
                            <Box component="div" className="how-do-i" style={{ marginBottom: '32px' }}>
                                {renderRichText(data?.detailsDescription1, renderingOptions)}
                            </Box>
                        )}
                    </>
                )}

                {data?.section1Image && (
                    <Box
                        src={data?.section1Image?.file?.url}
                        component="img"
                        style={{ marginBottom: '25px', marginTop: '2.5rem' }}
                        alt="asurion mobile"
                        sx={{ height: 'auto', width: '100%' }}
                    />
                )}
            </section>

            <section>
                {data?.detailsHeader2 && (
                    <>
                        <Box component="div">
                            <Typography
                                style={{
                                    fontSize: '16px',
                                    lineHeight: '23px',
                                    marginBottom: '28px',
                                    fontWeight: '800',
                                }}
                            >
                                {data?.detailsHeader2}
                            </Typography>
                        </Box>
                        {data?.detailsDescription2 && (
                            <Box component="div" className="how-do-i" style={{ marginBottom: '32px' }}>
                                {renderRichText(data?.detailsDescription2, renderingOptions)}
                            </Box>
                        )}
                    </>
                )}
                {data?.section2Image && (
                    <Box
                        src={data?.section2Image?.file?.url}
                        component="img"
                        style={{ marginBottom: '25px', marginTop: '2.5rem' }}
                        alt="asurion mobile"
                        sx={{ height: 'auto', width: '100%' }}
                    />
                )}
                {/* {data?.detailsDescription3 && (
                    <Box component="div" className="how-do-i" style={{ marginBottom: '32px' }}>
                        {renderRichText(data?.detailsDescription3, renderingOptions)}
                    </Box>
                )}
                {data?.detailsDescription6 && (
                    <Box component="div" className="how-do-i" style={{ marginBottom: '32px' }}>
                        {renderRichText(data?.detailsDescription6, renderingOptions)}
                    </Box>
                )} */}
            </section>
        </Box>
    );
};

export default AnswerExpanded;