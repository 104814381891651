import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Seo from '../components/SeoComponent';
import { Box, Container, Grid, Typography } from '@mui/material';
import { Link } from 'gatsby';
import Layout from '../components/LayoutComponent';
import AnswerExpanded from '../components/AnswerExpanded';

class SingleHowToTemplate extends React.Component {
    render() {
        let answer = get(this.props, 'data.howToData');

        const metaTitle = answer.metaTags && answer.metaTags.title ? answer.metaTags.title : '';
        const metaDescription = answer.metaTags && answer.metaTags.description ? answer.metaTags.description : '';
        // const domainUrl = process.env.GATSBY_DOMAIN_URL || '';
        const domainUrl = '';
        const canonicalUrl =
            this.props && this.props.pageContext && this.props.pageContext.canonicalPath
                ? domainUrl + this.props.pageContext.canonicalPath
                : domainUrl + this.props.path;

        const toastBarInfo = {
            toastBarText: get(this.props, 'contentfulD2CHomeGatsbyHomepage.toastBarText'),
            toastBarBackgroundColor: get(this.props, 'contentfulD2CHomeGatsbyHomepage.toastBarBackgroundColor'),
            toastBarEnabled: get(this.props, 'contentfulD2CHomeGatsbyHomepage.toastBarEnabled'),
        };

        return (
            <>
                <Seo title={metaTitle} description={metaDescription} canonical={canonicalUrl} />
                <Layout
                    subcomponent={
                        <>
                            <Container maxWidth="lg" sx={{ p: { xs: 0, md: '0 1rem' } }}>
                                <Container ssm sx={{ p: { xs: 0, md: '1rem' } }}>
                                    <Grid
                                        xs={12}
                                        md={8}
                                        sx={{ mr: 'auto', ml: 'auto', maxWidth: { xs: 'unset', md: '768px' } }}
                                    >
                                        <Box component="section">
                                            <Box
                                                component="div"
                                                style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    position: 'relative',
                                                    backgroundColor: '#8223D2',
                                                    height: 'auto',
                                                    padding: '20px',
                                                    overflowY: 'hidden',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: '50px',
                                                        textAlign: 'center',
                                                        color: '#fff',
                                                        display: 'flex',
                                                        lineHeight: '60px',
                                                    }}
                                                >
                                                    HOW DO I
                                                    <Box
                                                        component="span"
                                                        style={{
                                                            color: 'white',
                                                            position: 'relative',
                                                            fontWeight: '900',
                                                            marginLeft: '20px',
                                                            fontSize: '150px',
                                                            fontFamily: 'Arial',
                                                            marginTop: '-0.25rem',
                                                        }}
                                                    >
                                                        ?
                                                    </Box>
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <section className="mt-5" style={{ marginTop: '20px' }}>
                                            <Link
                                                underline="always"
                                                style={{
                                                    fontSize: '14px',
                                                    lineHeight: '21px',
                                                    marginLeft: '16px',
                                                    color: '#8223D2',
                                                    cursor: 'pointer',
                                                }}
                                                to={`/articles/how-to`}
                                            >
                                                {'<'} Back to list
                                            </Link>
                                            <AnswerExpanded data={answer} />
                                        </section>
                                    </Grid>
                                </Container>
                            </Container>
                        </>
                    }
                    toastBarInfo={toastBarInfo}
                />
            </>
        );
    }
}

export default SingleHowToTemplate;

export const pageQuery1 = graphql`
    query singleHowToQuery($slug: String) {
        howToData: contentfulHowDoIPage(questionUrl: { eq: $slug }) {
            createdAt
            checkOs
            top10
            question
            answer {
                raw
            }
            questionUrl
            detailsHeader1
            detailsDescription1 {
                raw
            }
            detailsHeader2
            detailsDescription2 {
                raw
            }
            section1Image {
                file {
                    url
                }
            }
            section2Image {
                file {
                    url
                }
            }
            metaTags {
                description
                canonical
                hrefLang
                title
                robots
            }
        }
        contentfulD2CHomeGatsbyHomepage {
            toastBarText {
                raw
            }
            toastBarBackgroundColor
            toastBarEnabled
        }
    }
`;
